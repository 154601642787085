import React from 'react';
import styles from './Radio.module.scss';
import classNames from 'classnames';

interface Props {
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: { label: string; value: string; checked?: boolean }[];
  size?: 's' | 'm' | 'l';
  orientation?: 'horizontal' | 'vertical';
  disabled?: boolean;
}

export const Radio: React.FC<Props> = ({
  name,
  onChange,
  options,
  size = 'm',
  orientation = 'horizontal',
  disabled,
}) => {
  return (
    <div
      className={classNames(
        styles['gaas-radio'],
        styles[`gaas-radio--${orientation}`]
      )}
      role={'radiogroup'}
      aria-label={name}
    >
      {options.map(option => (
        <div
          className={classNames(
            styles['gaas-radio--option'],
            styles[`gaas-radio--option--${size}`]
          )}
          key={option.value}
        >
          <div className={styles['gaas-radio--option--input']}>
            <input
              type="radio"
              name={name}
              value={option.value}
              id={option.label}
              checked={option.checked}
              onChange={onChange}
              disabled={disabled}
              aria-checked={option.checked}
            />
          </div>
          <label
            className={classNames(styles['gaas-radio--option--label'], {
              [styles['gaas-radio--option--label--disabled']]: disabled,
            })}
            htmlFor={option.label}
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};
