import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { Devider } from '../../../stories/Devider';
import styles from './ShopCheckout.module.scss';
import { Skeleton } from '../../../stories/Skeleton';
import { Input } from '../../../stories/Input';
import {
  ApiAddress,
  ApiGetShopAddressesResponse,
  ApiGetShopItemsResponse,
  ApiShopItem,
} from '../../../models/openapi/openapiTypes';
import * as shopSelectors from '../selectors';
import * as shopActions from '../actions';
import { ShippingAddress } from '../../../stories/ShippingAddress';
import { CartItem } from '../models/Cart';

const mapStateToProps = (state: RootState) => ({
  cart: shopSelectors.currentCart(state.shop),
});

const dispatchProps = {
  changeCartReference: shopActions.currentCartChangeReference,
};

interface ComponentProps {
  shopItemEntries: ApiGetShopItemsResponse;
  selectedShippingAddress: string | undefined;
  setSelectedShippingAddress: (addressId: string) => void;
  addresses: ApiGetShopAddressesResponse;
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopCheckout: FC<Props> = ({
  addresses,
  shopItemEntries,
  selectedShippingAddress,
  setSelectedShippingAddress,
  cart,
  changeCartReference,
}) => {
  useEffect(() => {
    setSelectedShippingAddress(addresses.billingAddress.addressId);
  }, [addresses, setSelectedShippingAddress]);

  let addressContent = (
    <>
      <h2>Rechnungsadresse</h2>
      <Skeleton height={24} width={150} mb={8} />
      <Skeleton height={24} width={150} mb={8} />
      <Skeleton height={24} width={120} />
      <Devider />
      <h2>Lieferung</h2>
      <Skeleton height={24} width={120} />
    </>
  );

  const cartItemsWithShopItem = cart.items
    .map(item => ({
      cartItem: item,
      shopItem: shopItemEntries?.find(it => it.itemId === item.itemId),
    }))
    .filter(
      (item): item is { cartItem: CartItem; shopItem: ApiShopItem } =>
        item.shopItem !== undefined
    );

  const shippableCartItems = cartItemsWithShopItem.filter(
    ({ shopItem }) =>
      shopItem.category !== 'user' && shopItem.category !== 'subscription'
  );

  if (selectedShippingAddress !== undefined) {
    const { billingAddress } = addresses;
    const usedShippingAddresses = Array.from(
      new Set(shippableCartItems.map(item => item.cartItem.shippingAddressId))
    );
    const usedShippingAddressData = usedShippingAddresses
      .map(addressId =>
        addresses.shippingAddresses.find(
          address => address.addressId === addressId
        )
      )
      .filter((address): address is ApiAddress => address !== undefined);

    addressContent = (
      <>
        <h2>Rechnungsadresse</h2>

        <ShippingAddress
          name={billingAddress.name}
          street={billingAddress.street}
          zipCode={billingAddress.zipCode}
          city={billingAddress.city}
          noDecoration
        />

        <Devider noMargin />

        {usedShippingAddressData.map((address, index) => (
          <div
            key={`delivery-${address.addressId}`}
            role={'region'}
            aria-label={'deliveries'}
          >
            <h2>Lieferung {index + 1}</h2>
            <ShippingAddress
              name={address.name}
              street={address.street}
              zipCode={address.zipCode}
              city={address.city}
            />

            <div className={styles['gaas-shopCheckout--list']}>
              {shippableCartItems
                .filter(
                  item => item.cartItem.shippingAddressId === address.addressId
                )
                .map(({ cartItem, shopItem }) => (
                  <div
                    className={styles['gaas-shopCheckout--list--item']}
                    key={`delivery-${address.addressId}-item-${cartItem.itemId}`}
                  >
                    <div
                      className={
                        styles['gaas-shopCheckout--list--item--quantity']
                      }
                    >
                      {cartItem.quantity}
                    </div>
                    {shopItem.name}
                  </div>
                ))}
            </div>
          </div>
        ))}

        <h2>Weitere Informationen</h2>
        <Input
          label="Vertragsreferenz"
          placeholder="Bestell- oder Vertragsnummer"
          type="text"
          max={35}
          onChange={event => {
            const newReference = event.target.value;
            changeCartReference({
              reference: newReference === '' ? undefined : newReference,
            });
          }}
          name="contract"
          value={cart.reference ?? ''}
        />
      </>
    );
  }

  return <div className={styles['gaas-shopCheckout']}>{addressContent}</div>;
};

export default connect(mapStateToProps, dispatchProps)(ShopCheckout);
